import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";

Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: en,
    menus: {
      home: "Home",
    },
    title: {
      fxEngine: "FX Engine"
    },
    instructions: {
      close: "Close",
      logout: "Logout",
      login: "Log In",
      cancel: "Cancel",
      save: "Save",
      next: "Next",
      back: "Back",
    },
    extras: {
      at: "at",
      readOnly: "Read-only",
      footer: "© 2023-2024 clearfx.ai All rights reserved.",
      language: "language",
    },
    fxEngine: {
      spotD2: "Spot D2",
      lastUpdate: "Last Update:",
      WDOJ24: "WDOJ24",
      casadoUsd: "Casado USD",
      USDBRL: "USDBRL",
      ask: "Ask",
      bid: "Bid",
      operationalSummary: "Operational Summary",
      lastSevenDays: "Last 7 Days",
      deals: "Deals",
      dealDestination: "DEAL destination address",
      spreads: {
        title: "Spreads",
        categories: {
          title: "Categories",
          foreignTrade: "FOREIGN_TRADE",
          foreignCapital: "FOREIGN_CAPITAL",
          capitalGains: "CAPITAL_GAINS",
          insurance: "INSURANCE",
          micellaneousServices: "MISCELLANEOUS_SERVICES",
          transport: "TRANSPORT",
        },
        notional: "Notional",
        status: {
          title: "Status",
          active: "Active",
          skip: "Skip",
        },
        category: "Category",
        valueBps: "Value (bps)",
        lastUpdate: "Last Update",
        edit: {
          title: "Edit",
          spread: "Spread",
          status: "Status",
          active: "Active",
          notional: "Notional",
          updatedSucces: "Updated with succes"
        },
      },
    },
    toolbar: {
      title: "Pricer",
      mesaCambio: "Mesa Cambio (demo) | BANCO LTDA",
      timeZone: "Time Zone: BRT",
      errorMessage: "Unable to log out. Try again later.",
    },
    requestHelp: {
      requestHelp: "Request Help",
      greeting: "Hi, how can we help you?",
      instruction: "Select how you would like to receive help from our Customer Representatives",
      sendEmail: {
        title: "Send Us An Email",
        btnText: "Send Email"
      },
      helpTicket: {
        title: "Submit A Help Ticket",
        description: "We will respond as soon as possible",
        btnText: "Start Ticket (TBR)"
      },
      liveChat: {
        title: "Live Chat",
        btnText: "Chat Now (TBR)"
      }
    },
    landing: {
      title: "Pricer",
      description: {
        descriptionOne: "Log in to monitor and set-up price strategies for an",
        descriptionTwo: "algorithmic execution of your FX trading desks.",
      },
      pricerConsole: {
        title: "clearfx.ai | Pricer",
        descriptionOne: "Adjust spreads, monitor operations requests and market",
        descriptionTwo: "rates, establish a remote-notification method for",
        descriptionThree: "successful deals and more.",
      },
      deliverQuotes: {
        title: "Deliver Accurate Quotes",
        descriptionOne: "Process multiple RFQs in under a second. Our intelligent",
        descriptionTwo: "pricing engine analyzes market conditions",
        descriptionThree: "in real-time to provide rapid and accurate quote",
        descriptionFour: "responses.",
      },
      streamlineOperations: {
        title: "Streamline FX Operations",
        descriptionOne: "Enable trading desks to gain a competitive edge through",
        descriptionTwo: "efficient management of your FX spreads strategy. Integrate",
        descriptionThree: "with existing infrastructures to deliver performance and",
        descriptionFour: "reliability to financial institutions of all sizes.",
      },
    },
    login: {
      title: "Login",
			description: {
				descriptionOne: "This is a secure clearfx.ai authentication service",
				descriptionTwo: "that allows you access to clearfx.ai services",
				descriptionThree: "from wherever you are.",
			},
      corporateEmail: "Corporate Email",
      password: "Password",
			invalidCredentials: "User authentication failed: invalid credentials"
    },
    notFound: {
      title: "404 Page not Found",
      descriptionOne: "I'm afraid you've found a page that doesn't exist on Clearfxai.",
      descriptionTwo: "That can happen when you follow a link to something that has since been deleted.",
      descriptionThree: "Or the link was incorrect to begin with.",
      apologies: "Sorry about that.",
    },
  },
  pt: {
    $vuetify: pt,
    menus: {
      home: "Início",
    },
    title: {
      fxEngine: "Motor FX"
    },
    instructions: {
      close: "Fechar",
      logout: "Sair",
      login: "Entrar",
      cancel: "Cancelar",
      save: "Salvar",
      next: "Continuar",
      back: "Voltar",
    },
    extras: {
      at: "às",
      readOnly: "Somente leitura",
      footer: "© 2023-2024 clearfx.ai Todos os direitos reservados.",
      language: "idioma",
    },
    fxEngine: {
      spotD2: "Spot D2",
      lastUpdate: "Última Atualização:",
      WDOJ24: "WDOJ24",
      casadoUsd: "Casado USD",
      USDBRL: "USDBRL",
      ask: "Venda",
      bid: "Compra",
      operationalSummary: "Resumo Operacional",
      lastSevenDays: "Últimos 7 Dias",
      deals: "Negócios",
      dealDestination: "Endereço de destino do negócio",
      spreads: {
        title: "Spreads",
        categories: {
          title: "Categorias",
          foreignTrade: "COMÉRCIO_EXTERIOR",
          foreignCapital: "CAPITAIS_ESTRANGEIROS",
          capitalGains: "RENDAS_CAPITAIS",
          insurance: "SEGUROS",
          micellaneousServices: "SERVIÇOS_DIVERSOS",
          transport: "TRANSPORTE",
        },
        notional: "Notional",
        status: {
          title: "Status",
          active: "Ativo",
          skip: "Inativo",
        },
        category: "Categoria",
        valueBps: "Valor (bps)",
        lastUpdate: "Última Atualização",
        edit: {
          title: "Editar",
          spread: "Spread",
          status: "Status",
          active: "Ativo",
          notional: "Notional",
          updatedSucces: "Atualizado com sucesso"
        },
      },
    },
    toolbar: {
      title: "Pricer",
      mesaCambio: "Mesa Cambio (demo) | BANCO LTDA",
      timeZone: "Fuso Horário: BRT",
      errorMessage: "Não foi possível sair. Tente novamente mais tarde.",
    },
    requestHelp: {
      requestHelp: "Pedir Ajuda",
      greeting: "Oi, como podemos ajudar você?",
      instruction: "Selecione como você gostaria de receber ajuda de nossos Representantes",
      sendEmail: {
        title: "Envie um Email",
        btnText: "Enviar Email"
      },
      helpTicket: {
        title: "Envie um Ticket de Ajuda",
        description: "Responderemos o mais rápido possível",
        btnText: "Iniciar Ticket (TBR)"
      },
      liveChat: {
        title: "Chat",
        btnText: "Conversar Agora (TBR)"
      }
    },
    landing: {
      title: "Pricer",
      description: {
        descriptionOne: "Entre para configurar a estratégia de preços e monitorar a",
        descriptionTwo: "execução algorítmica de suas mesas de câmbio.",
      },
      pricerConsole: {
        title: "clearfx.ai | Pricer",
        descriptionOne: "Ajuste spreads, monitore solicitações de operações e taxas de",
        descriptionTwo: "mercado, estabeleça um método de notificação remota para",
        descriptionThree: "negócios bem-sucedidos e mais.",
      },
      deliverQuotes: {
        title: "Entregue Cotações Precisas",
        descriptionOne: "Processe múltiplas RFQs em menos de um segundo. Nosso motor",
        descriptionTwo: "de preços inteligente analisa as condições do mercado e vários",
        descriptionThree: "parâmetros em tempo real para fornecer respostas de cotações",
        descriptionFour: "rápidas e precisas.",
      },
      streamlineOperations: {
        title: "Simplifique Operações de FX",
        descriptionOne: "Permita que as mesas de câmbio obtenham uma vantagem competitiva através",
        descriptionTwo: "do gerenciamento eficiente da sua estratégia de spreads FX. Integre",
        descriptionThree: "com infraestruturas existentes para entregar desempenho e",
        descriptionFour: "confiabilidade para instituições financeiras de todos os tamanhos.",
      },
    },
    login: {
      title: "Login",
			description: {
				descriptionOne: "Este é um serviço de autenticação seguro clearfx.ai",
				descriptionTwo: "que permite acessar os serviços clearfx.ai",
				descriptionThree: "de onde você estiver.",
			},
      corporateEmail: "Email Corporativo",
      password: "Senha",
			invalidCredentials: "User authentication failed: invalid credentials"
    },
    notFound: {
      title: "404 Página não Encontrada",
      descriptionOne: "Receio que você tenha encontrado uma página que não existe na Clearfxai.",
      descriptionTwo: "Isso pode acontecer quando você segue um link para algo que já foi excluído.",
      descriptionThree: "Ou o link estava incorreto.",
      apologies: "Desculpe por isso.",
    },
  }
};

const initialLocale = localStorage.getItem('userLanguage') || 'en';

const i18n = new VueI18n({
  locale: initialLocale,
  fallbackLocale: 'en',
  messages,
});

export default i18n;
